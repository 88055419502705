import './heading.css'

const Heading = ({title}) => {
    return (
        <div className="section_heading">
            <h2 className='info_sections_heading'>{title}</h2>
        </div>
    )
}

export default Heading