import { ahbcPhotos } from '../data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../pages/services/services.css';

const ServicesCarousel = () => {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
};

    return (
        <>
            <Slider {...settings}>
                {
                    ahbcPhotos.map((photo)=>{
                    return <div className="photography_images_container">
                        <div className="photography_image_card">
                            <img src={photo.src} alt="photography services images" />
                        </div>
                    </div>
                    })
                }
            </Slider>
        </>
    )
}

export default ServicesCarousel