import { useEffect } from 'react';
import SloganImage from '../../images/our_vision.png';
import VisionImage from '../../images/binoculars.png';
import MissionImage from '../../images/target.png';
import './about.css';
import AOS from 'aos';
import { motion as m } from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import 'aos/dist/aos.css';


const About = () => {
  useEffect(()=>{
    AOS.init({
      duration: 2500, // values from 0 to 3000, with step 50ms
      //offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
    });
    }, [])
  return (
    <m.div
      initial={{x: "-100%"}}
      animate={{x: "-0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >
      <ScrollToTop/>
      <section className="about_story">
        <div className="container about_story_container">
          <div className="about_section_content_story">
            <h2>Who We Are</h2>
            <br/>
            <h1>At Rich Mansion we focus on data-driven strategies and a customer-centric approach, we create marketing campaigns that deliver measurable results and drive real business growth.</h1>
            <p>
                We  place a strong emphasis on transparency and open communication with our clients. We believe that it's important for our clients to understand exactly what we're doing and why, and we make sure to keep our clients informed every step of the way, in order to ensure that our marketing campaigns are meeting goals and driving real business results.
            </p>
          </div>
        </div>
      </section>   
      <section className="about_slogan">
        <div data-aos='fade-left' className="container about_slogan_container">
        <div className="about_section_image about_section_image_right">
            <img src={SloganImage} alt="Slogan Image" />
          </div>
          <div className="about_section_content slogan_cont">
            <h3>Our Slogan</h3>
            <p>
              Think outside the box, break the mold and lead the way with our forward-thinking digital marketing solutions.
            </p>
          </div>
        </div>
      </section>  
      <section className="about_Mission_Vission">
        <div className="container about_mission_vission_container">
            <div data-aos='fade-up' className='vission_cont'>
              <div className="about_section_image">
                <img src={VisionImage} alt="Mission Image" />
              </div>
              <div className="about_section_content">
                <h3>Our Vision</h3>
                <p>
                At Rich Mansion, our vision is to be the premier provider of digital marketing services for businesses of all sizes. We aim to be the go-to partner for companies looking to grow their online presence & reach their full potential. 
                </p>
              </div>
          </div>
          <div data-aos='fade-up' className='mission_cont'>
            <div className="about_section_image">
              <img src={MissionImage} alt="Mission Image" />
            </div>
            <div className="about_section_content">
              <h3>Our Mission</h3>
              <p>
              Our mission at Rich Mansion is to empower businesses of all sizes to reach their full potential online. We believe that in today's digital age, having a strong online presence as a business is crucial for success. 
              </p>
            </div>
          </div>
        </div>
      </section>  
    </m.div>
  )
}

export default About