import {SiOpenaigym} from 'react-icons/si';


export const serviceslinks = [
    {
        name: "Photography",
        path: '/photography',
        cName: 'dropdown-link'
    },
    {
        name: "Videography",
        path: '/videography',
        cName: 'dropdown-link'
    }
]


export const adcartlinks = [
    // {
    //     name: "The AdCart Magazine",
    //     path: '/adcart-magazine',
    //     cName: 'dropdown-link'
    // },
    {
        name: "The AdCart Downloads",
        path: '/adcart-downloads',
        cName: 'dropdown-link'
    }
]







export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "MARKETING & STRATEGY",
        info: "Drive valuable sales for your business.",
        path: "/services"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "CREATIVE",
        info: "Let your message stand out and be felt.",
        path: "/services"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "DEVELOPMENT",
        info: "Your website is the customer’s first impression of your brand.",
        path: "/services"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "BRANDING",
        info: "Show case your brand's personality",
        path: "/services"
    }
]







export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Focus on impact",
        desc: "At our company, we believe that it's important to focus on impact in everything we do, we strive to make a meaningful and positive difference."
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Innovative",
        desc: "We value staying at the forefront of digital marketing trends and technologies, & we are constantly experimenting with new ways to reach & engage customers."
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Data-driven",
        desc: "We value using data and analytics to inform our marketing strategies, and believe that data should be at the center of all decision making."
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Customer focus",
        desc: "We value placing the needs of our customers first, and we are committed to delivering excellent customer experiences at every touchpoint."
    }
]









export const faqs = [
    {
        id: 1,
        question: "What is digital marketing?",
        answer: "Digital marketing encompasses all forms of advertising and marketing using the internet, from search engine optimization (SEO) to content marketing, social media and more."
    },
    {
        id: 2,
        question: "What goes into digital marketing?",
        answer: "There are a variety of tactics which make up digital marketing. At Rich Mansion, our digital experts tackle: Search Engine Optimization (SEO), Display Advertising, Search Engine Marketing (SEM), Content Marketing, Email Marketing, Social Media Management, and Research."
    },
    {
        id: 3,
        question: "How can digital marketing help your business?",
        answer: "Do you have an audience that spends time online? Nowadays, the answer is yes for just about every business. Digital marketing will help you reach your target audience online and drive valuable conversions. Digital marketing is so versatile, it can be tailored to suit just about any brand!"
    },
    {
        id: 4,
        question: "Can digital marketing increase online sales?",
        answer: "Absolutely! The goal of digital marketing is to improve website traffic and online conversions, thus helping you reach your overall business goals. If you’d like to ultimately increase online sales, we would recommend a variety of tactics to reach this goal. These may range from competitive research and conversion rate optimization to social media. When we’re developing a campaign for a client, we get to know their business and objectives, then propose plans that align with those goals."
    },
    {
        id: 5,
        question: "What are some of the digital marketing platforms?",
        answer: "Digital marketing platforms are websites and/or online platforms used to execute digital strategies. Facebook, Twitter and LinkedIn are all digital platforms which provide marketers with the tools to share their messaging with a desired audience."
    },
    {
        id: 6,
        question: "What are some of the digital marketing tools we use?",
        answer: "This depends on your goals, but there are a few digital marketing tools which are relevant for every business: Google Analytics and Google Search Console."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "John Doe",
        quote: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
        job: "Lorem ipsum",
        avatar: require("./images/avatar1.png")
    },
    {
        id: 2,
        name: "John Doe",
        quote: "Harum quaerat hic consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum this and that odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "Lorem ipsum",
        avatar: require("./images/avatar2.png")
    },
    {
        id: 3,
        name: "John Doe",
        quote: "Quaerat hic praesentium consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "Lorem ipsum",
        avatar: require("./images/avatar3.png")
    },
    {
        id: 4,
        name: "John Doe",
        quote: "Cupiditate deleniti sint consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "Lorem ipsum",
        avatar: require("./images/avatar4.png")
    },
    {
        id: 5,
        name: "John Doe",
        quote: "Maxime minima cumque sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
        job: "Lorem ipsum",
        avatar: require("./images/avatar5.png")
    }
]







export const plans = [
    {
        id: 1,
        name: 'Social Media Marketing',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        path: "/social",
        features: [
            {feature: 'Social media auditing', available: true},
            {feature: 'Set up & optimization', available: true},
            {feature: 'Audience engagement', available: true},
            {feature: 'Content development', available: true},
            {feature: 'Content management', available: true}
        ]
    },
    {
        id: 3,
        name: 'Paid Media',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        path: "/ppc",
        features: [
            {feature: 'Google Search Advertising (PPC)', available: true},
            {feature: 'Google Display (PPC)', available: true},
            {feature: 'Social Media Paid Ads', available: true},
            {feature: 'Emai Advertising', available: true}
        ]
    },
    {
        id: 2,
        name: 'SEO',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        path: "/seo",
        features: [
            {feature: 'SEO Optimization', available: true},
            {feature: 'Link Building', available: true},
            {feature: 'Content Creation', available: true},
            {feature: 'Pay-Per-Click Search Ads', available: true},
            {feature: 'Online Reputation Management', available: true}
        ]
    }
]


export const socialPlans = [
    {
        id: 1,
        name: 'Bronze Package',
        desc: 'Target: Facebook, Twitter, Instagram',
        price: 'Ksh 9,999',
        path: "/contact",
        styling: 'plan silver',
        features: [
            {feature: 'Frequency: X3 Per Week (12 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Silver Package',
        desc: 'Target: Facebook, Twitter, Instagram',
        price: 'Ksh 13,999',
        path: "/contact",
        styling: 'plan bronze',
        features: [
            {feature: 'Frequency: X5 Per Week (20 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true},
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        desc: 'Target: Facebook, Twitter, Instagram',
        price: 'Ksh 18,499',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X7 Per Week (28 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        desc: 'Target: Facebook, Twitter, Instagram',
        price: 'Ksh 22,999',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X11 Per Week (44 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive ', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    }
]


export const googlePlans = [
    {
        id: 1,
        name: 'Bronze Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 9,999',
        path: "/contact",
        styling: 'plan silver',
        features: [
            {feature: 'Frequency: X3 Per Week (12 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Silver Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 13,999',
        path: "/contact",
        styling: 'plan bronze',
        features: [
            {feature: 'Frequency: X5 Per Week (20 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true},
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 18,499',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X7 Per Week (28 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 22,999',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X11 Per Week (44 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive ', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    }
]


export const seoPlans = [
    {
        id: 1,
        name: 'Bronze Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 9,999',
        path: "/contact",
        styling: 'plan silver',
        features: [
            {feature: 'Frequency: X3 Per Week (12 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Silver Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 13,999',
        path: "/contact",
        styling: 'plan bronze',
        features: [
            {feature: 'Frequency: X5 Per Week (20 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true},
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 18,499',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X7 Per Week (28 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        // desc: 'Quaerat hic praesentium consequuntur molestias repellat ad',
        price: 'Ksh 22,999',
        path: "/contact",
        styling: 'plan',
        features: [
            {feature: 'Frequency: X11 Per Week (44 Ads)', available: true},
            {feature: 'Design Inclusive', available: true},
            {feature: 'Boosted AD: Inclusive ', available: true},
            {feature: 'Special AD Request: 1,000/-', available: true},
            {feature: 'Engagement: 3 Months', available: true}
        ]
    }
]


const Trainer1 = require('./images/trainer1.png')
const Trainer2 = require('./images/trainer2.png')
const Trainer3 = require('./images/trainer3.png')
// const Trainer4 = require('./images/trainer4.png')
// const Trainer5 = require('./images/trainer5.png')
// const Trainer6 = require('./images/trainer6.png')


export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'John Doe',
        job: "Lorem ipsum",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Trainer2,
        name: 'John Doe',
        job: "Lorem ipsum",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Trainer3,
        name: 'John Doe',
        job: "Lorem ipsum",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    // {
    //     id: 4,
    //     image: Trainer4,
    //     name: 'John Doe',
    //     job: "Lorem ipsum",
    //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    // },
    // {
    //     id: 5,
    //     image: Trainer5,
    //     name: 'John Doe',
    //     job: "Lorem ipsum",
    //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    // },
    // {
    //     id: 6,
    //     image: Trainer6,
    //     name: 'John Doe',
    //     job: "Lorem ipsum",
    //     socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    // }
]


const Client1 = require('./images/vintage.jpg')
const Client2 = require('./images/erdemann.png')
const Client3 = require('./images/gallery3.png')
const Client4 = require('./images/tre.jpg')
const Client5 = require('./images/baobab.jpg')
const Client6 = require('./images/gallery6.jpg')
const Client7 = require('./images/gallery7.jpg')
const Client8 = require('./images/gallery8.jpg')
const Client9 = require('./images/ahbc.jpg')
const Client10 = require('./images/vintage2.jpg')


export const clients = [
    // categories: [all, photos, videos, content, branding, markenting, webdev]
    {
        image: Client10,
        name: 'Vintage Residence',
        path: '/vintage-video',
        category: ["portfolio", "videos"],
        id: 1,
    },
    {
        image: Client2,
        name: 'Erdemann Property Ltd',
        path: '/erdemann-video',
        category: ["portfolio", "videos"],
        id: 2,
    },
    {
        image: Client3,
        name: 'Arbor House Business Center',
        path: '/ahbc-video',
        category: ["portfolio", "videos"],
        id: 3,
    },
    {
        image: Client4,
        name: 'The River Estate',
        path: '/tre-video',
        category: ["portfolio", "videos"],
        id: 4,
    },
    {
        image: Client5,
        name: 'Boabab Plaza',
        path: '/boabab-video',
        category: ["portfolio", "videos"],
        id: 5,
    },
    {
        image: Client6,
        name: 'Arbor House Arbitration Centre',
        path: '/ahac-video',
        category: ["portfolio", "videos"],
        id: 6,
    },
    {
        image: Client8,
        name: 'Supreme Dental Centre',
        path: '/sd',
        category: ["portfolio", "photos"],
        id: 7,
    }
]


export const photographyClients = [
    // categories: [all, ephoto, pphoto, rphoto, cphoto, cophoto]
    {
        image: Client7,
        name: 'Arbor House Arbitration Centre',
        path: '/ahac-photography',
        category: ["pportfolio", "rphoto"],
        id: 11,
    },
    {
        image: Client8,
        name: 'Supreme Dental Centre',
        path: '/sd',
        category: ["pportfolio", "cophoto"],
        id: 12,
    },
    {
        image: Client1,
        name: 'Vintage Residence',
        path: '/vintage-photo',
        category: ["pportfolio", "rphoto"],
        id: 13,
    },
    {
        image: Client9,
        name: 'Abor House Business Center',
        path: '/ahbc-photo',
        category: ["pportfolio", "rphoto"],
        id: 14,
    }
]


export const videographyClients = [
    // categories: [all, rvideo, avideo, cvideo, pvideo, tvideo]
    {
        image: Client1,
        name: 'Vintage Residence',
        path: '/vintage-video',
        category: ["vportfolio", "rvideo"],
        id: 14,
    },
    {
        image: Client2,
        name: 'Erdemann Property Ltd',
        path: '/erdemann-video',
        category: ["vportfolio", "rvideo"],
        id: 15,
    },
    {
        image: Client3,
        name: 'Arbor House Business Center',
        path: '/ahbc-video',
        category: ["vportfolio", "rvideo"],
        id: 16,
    },
    {
        image: Client4,
        name: 'The River Estate',
        path: '/tre-video',
        category: ["vportfolio", "rvideo"],
        id: 17,
    },
    {
        image: Client5,
        name: 'Boabab Plaza',
        path: '/boabab-video',
        category: ["vportfolio", "rvideo"],
        id: 18,
    },
    {
        image: Client6,
        name: 'Arbor House Arbitration Centre',
        path: '/ahac-video',
        category: ["vportfolio", "rvideo"],
        id: 19,
    }
]


export const photography = [

    {
        src: "https://drive.google.com/uc?export=view&id=1q9DuH6rCRinMgRUPsfFmJYnk7szmL0Ud",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1NMLkh46saH0ZD35qDY2TNPUy_8KkofkD",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1T2fQpFUlQumGpnRwGlHTg6HXRJExlTbn",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1rBldJ7bnDP1VfOVAlUoQoC4x0iMiQkJ6",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Ain8EUWPvVhYndf0FrP1VPIOmiAZHwn3",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1a6zuD55xt7uQBujPV6pu_PspNyqRO_FZ",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1x0yb05utokXiQvimh4zHw1QUXMy_yyTi",
        id: 7
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1-JAsGfIgjW4rhqwPnBi9rIyLG87R0nA9",
        id: 8    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Uy8JOjVn4bY6GjuzVVkNHt4KqLqwKXrv",
        id: 9
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1iVdPdQkSpt4ZueJOWY-8QLCpqJJxxEAy",
        id: 10,
    }
    ,{
        src: "https://drive.google.com/uc?export=view&id=1iVdPdQkSpt4ZueJOWY-8QLCpqJJxxEAy",
        id: 11,
    }
]


export const supremePhotos = [

    {
        src: "https://drive.google.com/uc?export=view&id=1U_GGJOO1c2ro3urv-5aX88jC2yorPCiI",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=14zf6jCHUngTU6OOGMFi9iYyBahmrorw6",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1rYz71kJD2okpb_Flsh1Hmi3BkUgXOY4X",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1GlS4chRj3dRfsOLn-5VDUNUgZ_0U_9gg",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=10B6PFnORV-FNdY-e3zNoreeEkOCLeiNk",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=17i5ZdnvebsRbJq1hjxi3tuEzNm0jsCbW",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1HS3s454FK9dOJFt5R5vAmLtq8fCXxhS1",
        id: 7
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1OSP-EebWh-mKqmBW8Rb6R3h_MBLTZRJ_",
        id: 8    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=165eoauZJFha2uIb67IuGAHDX3wBzyBKO",
        id: 9
    },
    {
        src: "https://drive.google.com/uc?export=view&id=16kFpOYcbSqyTFdP6YvAwMN2294pyqSFi",
        id: 10,
    }
]


export const ahacPhotos = [

    {
        src: "https://drive.google.com/uc?export=view&id=12v-MwU5kngR97B9BX5k9ZUG57PRtmbIH",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1w395xzu0x6oSkk-7RRFYARmMQsJ1rgyx",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1TrW-WIFb4KbfAXiQTcX-j15e8DjX19ha",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1-tZixrzapRr-yaFn_SF-HKwlcXQ9MYln",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1RBBR6Tiv4gg-YEOsuaUcKsWhAth_ulgq",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1njGPVBB2u90rRkFI4PSdY_pqs0metoMf",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1NLdj88eOeJDxdoUNbCKn2wOsPAn6a2fa",
        id: 7
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1aTc9Vj8-JN8MZ4Td8nLA64kgKQBpteKO",
        id: 8    
    }
]

export const ahaclaunchPhotos = [

    {
        src: "https://drive.google.com/uc?export=view&id=11fiHX25aoITDei8vbJ-4sC26UlAdTRmM",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1L8NtXZRf7R_plogt7OQFoeON4e9BT5Yz",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Nt8HDdV99w9M8A6MlMwTUEJUH0IKToka",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1jVYykpLUlP1tvHi0SjeNGCvX0fLPtnKE",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=10KsOqaTUB2PAa_IcSGpRMHZ0e06PBCjO",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1vV2wE0b5pLW8BWQF845CuyxloAtBSgJ1",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1shJV4NLmqy4TgRm96psZeMN0FWk65T8k",
        id: 7
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1l2gDVbGXxYuLA4WMwu0CjApZ8gYc0jP2",
        id: 8    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1H54tk4RmJPkmq6yypUi0I30Mdo9VHxRm",
        id: 9
    }
]

export const ahbcPhotos = [

    {
        src: "https://drive.google.com/uc?export=view&id=1thNHYZgfyliE2l2CLEV_zArmoFOnSNTl",
        id: 1   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1iOLnt8YGcXIVCKmMdoM1FEeXxrDehKsU",
        id: 2
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1AqKvbhLlqDvIyyY_D3hytVV15aiJDMht",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Qw3NmY5iqbaJf10Szm9ge64NX12DCYnN",
        id: 4   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1J0d7WYRwQ6gSJeDFqrykWrFnT4EY_4PZ",
        id: 5
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1EkgzuPELJ0RHr5dSf1tPAFYrbr3OqFcT",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1urWUGfaCgFKQQfh-1XxI8JPPZHfQYcbw",
        id: 7    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1zLcYNxadqV63eRg29kQXBkSunyzUqeql",
        id: 8
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1zgdCmK69-U3S2qM3IG14hzfFKNpEOAw2",
        id: 9,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1bJVyf7ccK-L9scq73x3IpMCfchtr14Oe",
        id: 10,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1bJVyf7ccK-L9scq73x3IpMCfchtr14Oe",
        id: 11,
    },
]

export const vintagePhotos = [

    {
        src: "https://drive.google.com/uc?export=view&id=1uvnJPOqRltwag252SJItjdoj8pBVki8M",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1EN1F_coCT0c7dczRvx9dbJ_hfEkVDngK",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=149CxDUyQFoeLqGgAdezCm3LEfK8-IMni",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=17hGg38RKW57rGMubJWnCvnbdiSVlnZtR",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1u4cP0iJI05eaQm_2q8anXfnPu3fmvF-p",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1S9FgihBlPEat89725TYHznNInh0Tz-rj",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1S9FgihBlPEat89725TYHznNInh0Tz-rj",
        id: 7
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1c8ax96If19Xp9R0gVPIYHKkiJwL0iy9o",
        id: 8    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=12Hht6sRuI8xhz5lWuK3UFaXexWXTga3F",
        id: 9
    },
    {
        src: "https://drive.google.com/uc?export=view&id=14VNbvzYHvVLCDNVxEezlXgy5iiIKk5Gy",
        id: 10,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Dghebkk4WrnF4Iz5pv0kDD9JzN8qB08V",
        id: 11,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1zuMeL3z86jzcBXnBJ70HCezsf1UV4rPo",
        id: 12,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1up97PE1ovzMVN54ZEMwlrJBG7WrbU9TX",
        id: 13,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1cebWkoRkMIVkHEwGhHKCPAmSOIV4e-bx",
        id: 14,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1ZanENFuxxN3SO6_OBexV-y6aA7s2yf59",
        id: 15,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Dz5-E5KR0nGDftveSmsvMERw0Us0YmKF",
        id: 16,
    }
]


export const techstackicons = [

    {
        src: "https://drive.google.com/uc?export=view&id=1vr8UmJJANBoEBJxVnr1u76ohj4XEQyX6",
        id: 1,
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1CYjtzhPAHpY_y8VWee7AbanLvl3nbL-N",
        id: 2    
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1g3Gv4u_7Hi-7wwi9oENIHlD06QHAZUBW",
        id: 3
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1bAi4V40SxIRoYllFyJEZcyP7uBeVoiRc",
        id: 4
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1pnbYftDe4H52IbwR7lHBzkLhVf-PR5HH",
        id: 5   
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1Kij-JzvZ3oUIX7izn22DTfldAPTVssKO",
        id: 6
    },
    {
        src: "https://drive.google.com/uc?export=view&id=1DpYTTXo3y5FyUQK0DbMh6Z5XqHWmnMZL",
        id: 7
    },
    {   
        src: "https://drive.google.com/uc?export=view&id=1tf0K69-mPHCFI1yYZ3JfQzeU_cvQfCCh",
        id: 8
    }
]