import Header from '../../components/Header';
import HeaderImage from '../../images/header_bg_one.png';
import Card from '../../UI/Card';
import { Link } from 'react-router-dom';
// import Subscribe from '../../components/Subscribe';
import {socialPlans} from '../../data';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';
import './social.css';

const Social = () => {
  return (
    <m.div
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{ duration: 0.9, delay:0.5, ease: "easeInOut"}}
      exit={{opacity: 1}}
    > 
      <ScrollToTop/>
      <Header title='Social Media Marketing' image={HeaderImage}>
        
      </Header>

      <section className='container plans-page-nav-cont'>

      <div className='plans-btn-heading'>
          <p>We use relevant industry experience to make your brand not just popular but relevant on various social media platforms such as Facebook, Instagram, YouTube, Twitter, etc. Our social media marketing services are designed in a way that you can clearly connect with your audeince, monitor engagement and drive relevant conversions. view that people are talking about you and offer you a better area, where you can get involved the best to increase brand awareness.</p>
        </div>
            
        <div className="plans-page-navigation">
          <div className="plans-button-one">
            <Link to='/social'>
                <button>
                  Social Media
                </button>
            </Link>
          </div>
          <div className="plans-button-two">
            <Link to='/ppc'>
                <button>
                  Paid Media
                </button>
            </Link>
          </div>
          <div className="plans-button-three">
            <Link to='/seo'>
                <button>
                  SEO
                </button>
            </Link>
          </div>
        </div>
      </section>



      <section className="plans">

        <div className="container plans_container">
          {
            socialPlans.map(({id, name, desc, price, path, styling, features}) => {
              return <Card key={id} className={styling}>
                <h3>{name}</h3>
                <small>{desc}</small>
                <h1>{`${price}`}</h1>
                <h2>/mo</h2>
                <h4>Features</h4>
                <div className='plans_text_content'>
                  {
                    features.map(({feature, available}, index) => {
                      return <p key={index} className={!available ? 'disabled' : ''}>
                        {feature}
                      </p>
                    })
                  }
                </div>
                <Link to={path} className='btn sm'>Choose Plan</Link>
              </Card>
            })
          }
        </div>
      </section>

      {/* <Subscribe/> */}

    </m.div>
  )
}

export default Social