import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import HeaderClient from '../../images/ahbc.jpg';
import AhbcCarousel from '../../components/ahbcCarousel.jsx'
import { Parallax } from 'react-scroll-parallax';
import AHBC from '../../images/our clients/ahbc.png';
import './ahbcphoto.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AhbcPhoto = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <Header title='Arbor House Business Center' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-ahbc">
                                <img className="ahbc-client-logo" src={AHBC} alt="Arbor House Arbitration Center Logo" />
                        </span>
                        <span className="client-name">Client: Arbor House Business Centre</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Arbor House Business Centre is a modern and inspiring development that offers a variety of flexible solutions from hot-desking to dedicated serviced virtual offices to private meeting rooms. This innovative, tech-forward space is designed to boost productivity and efficiency. It will appeal to any discerning business from creative start-ups to established businesses.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>Arbor House Business Center is a modern and inspiring development that offers a variety of flexible solutions from hot-desking to dedicated serviced virtual offices to private meeting rooms.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p>AHBC is located in close proximity to most major commercial centers including the Central Business District(CBD), Westlands, Karen, The Junction, Lavington, Upper hill, and Kilimani area.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3>
                            <p>Videography & Photography</p>
                        </article>
                    </div>
                    <br/>
                    <br/>
                    <AhbcCarousel/>
                    {/* <article data-aos='fade-up' className="container resultsContainer">
                        <h2 className='clientResultsHeading'>The Results</h2>
                        <ul>
                            <li>1. Lorem ipsum dolor sit amet.</li>
                            <li>2. Lorem ipsum dolor sit amet.</li>
                            <li>3. Lorem ipsum dolor sit amet.</li>
                            <li>4. Lorem ipsum dolor sit amet.</li>
                            <li>5. Lorem ipsum dolor sit amet.</li>
                            <li>6. Lorem ipsum dolor sit amet.</li>
                        </ul>
                    </article> */}
                </div>
            </section>
        </>
    )
}

export default AhbcPhoto