import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import SupremeHeader from '../../images/gallery8.jpg';
import SupremeCarousel from '../../components/SupremeCarousel.jsx';
import ScrollToTop from '../../components/ScrollToTop';
import { Parallax } from 'react-scroll-parallax';
import SUPREME from '../../images/our clients/sd.png';
import './supreme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SupremeDental = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>

            <ScrollToTop/>
            <Header title='Supreme Dental Centre' image={SupremeHeader}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-sd">
                                <img className="sd-client-logo" src={SUPREME} alt="Supreme Dental Center Logo" />
                        </span>
                        <span className="client-name">Client: Supreme Dental Centre</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Supreme Dental Centre is a multi-specialty dental practice offering a range of dental care services incorporated in 2014. Supreme is registered by the Medical Practitioners and Dentists Board as a provider of General and Specialist dentistry. Supreme Dental Centre provides a wide range of dental services to different clientele within Nairobi County and its environs.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>While looking at a target audience of Supreme Dental Centre, looking for Dental services within Kitengela Town. The newly established branch helped to cement Supreme’s Dental Centre brand story, the new branch is inspiring and full of visual space aesthetics that will revamp the brand activities and engagements both offline and online. The look & feel of this new branch remained to reflect the simple modern and contemporary feel of Supreme Dental Centre clinic’s space aesthetics. </p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p> Standard Building 5th Floor, At the junction of Kenyatta Avenue and Wabera street, Opp. Standard Chartered Bank, Nairobi.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3>
                            <p>Photography</p>
                        </article>
                    </div>
                    <article>
                    <SupremeCarousel/>
                    </article>
                </div>
            </section>
        </>
    )
}

export default SupremeDental