import { useEffect } from 'react';
import {FaCrown} from 'react-icons/fa';
import SectionHead from './SectionHead';
import {programs} from '../data';
import Card from '../UI/Card'
import {Link} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Programs = () => {
    useEffect(()=>{
        AOS.init({
          // Global settings:
          //disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
          //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
          //initClassName: 'aos-init', // class applied after initialization
          //animatedClassName: 'aos-animate', // class applied on animation
          //useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
          //disableMutationObserver: false, // disables automatic mutations' detections (advanced)
          //debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
          //throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        
          // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          duration: 2500, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
          //once: false, // whether animation should happen only once - while scrolling down
          //mirror: false, // whether elements should animate out while scrolling past them
          //anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        
        });
        }, [])
    return (
        <section className="programs">
            <div className="container programs_container">
                <SectionHead icon={<FaCrown/>} title='Services'/>
        
                <div className="programs_wrapper">
                    {
                        programs.map(({id, icon, title, info, path}) => {
                            return (
                                <Card className='programs_program' key={id}>
                                    <span className='programs_card_icon'>{icon}</span>
                                    <h4 className='programs_title_text'>{title}</h4>
                                    <small>{info}</small>
                                    <Link to={path} className='btn sm'>Learn More</Link>
                                </Card>
                            )
                        })
                    }
                </div>

            </div>
        </section>
    )
}

export default Programs