import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import HeaderClient from '../../images/tre.jpg';
import { Parallax } from 'react-scroll-parallax';
import ScrollToTop from '../../components/ScrollToTop';
import YoutubeEmbed from "../../components/YoutubeEmbed";
import TRE from '../../images/our clients/tre.png';
import './trevideo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TreVideo = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <ScrollToTop/>
            <Header title='The River Estate' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-tre">
                                <img className="tre-client-logo" src={TRE} alt="The River Estate Logo" />
                        </span>
                        <span className="client-name">Client: The River Estate</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Founded in 2003 and based in Nairobi – Kenya, ERDEMANN PROPERTY LIMITED (“EPL”) is a limited Liability Company specializing in property development. EPL provides decent, low-cost shelter to Kenyans by employing the latest technology in the art of building construction. We emphasize on delivery of quality and affordable housing with an unwavering dedication to our clients. EPL has built more than 4000 housing units from the year 2003 to date, and has cultivated numerous relationships which have earned us the trust of key stakeholders in the real estate industry.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>The River Estate is Located right at the heart of Nairobi in Ngara, Along Jodongo Road next to Ngara Girls High School. The project will comprise 8 aprapartment blocks, each 34 floors high, with 340 units per block. Located right at the heart of Nairobi, this iconic project is bound to change the Nairobi Skyline, this will be the first project of its magnitude in East and Central Africa; that will give the residents access to the beautiful panoramic views of the Nairobi city and its environs.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p>Ngara, Along Jodongo road next to Ngara Girls High School.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project Scope</h3>
                            <p>Videography</p>
                        </article>
                    </div>
                    <article>
                    <YoutubeEmbed embedId="TA4yzJWb1IA" />
                    </article>
                    {/* <article data-aos='fade-up' className="container resultsContainer">
                        <p>Results</p>
                        <p>1,300k Youtube Video Playthroughs</p>
                    </article> */}
                </div>
            </section>
        </>
    )
}

export default TreVideo