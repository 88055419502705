import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import HeaderClient from '../../images/baobab.jpg';
import { Parallax } from 'react-scroll-parallax';
import ScrollToTop from '../../components/ScrollToTop';
import YoutubeEmbed from "../../components/YoutubeEmbed";
import BAOBAB from '../../images/our clients/bob.png';
import './boababvideo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BoababVideo = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <ScrollToTop/>
            <Header title='Boabab Plaza' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-baobab">
                                <img className="baobab-client-logo" src={BAOBAB} alt="BAOBAB Logo" />
                        </span>
                        <span className="client-name">Client: Baobab Plaza Kilifi</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Baobab Plaza is an exquisite Commercial and Office Complex that is tucked right in the heart of Kilifi's Central Business District. Baobab Plaza will change the landscape of this fast-growing town. Baobab Plaza is located on Charo Wa Mae Road, right next to KCB, Kilifi Branch, and opposite Co-operative Bank of Kenya, Kilifi Branch. The architecture is a tasteful marriage of Swahili and Moorish architecture.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The Project</h2>
                            <p>This eight-storied development will offer an unparalleled office and retail environment for the growing business community of Kilifi. The external finishes will be a blend of modern and eco-friendly finishes and antique local stone cladding juxtaposed with large spandrel windows creating the balance of the two diverse architectural experiences.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p>Baobab Plaza is located on Charo Wa Mae Road, right next to KCB, Kilifi Branch, and opposite Co-operative Bank of Kenya, Kilifi Branch.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3>
                            <p>Videography</p>
                        </article>
                    </div>
                    <article>
                    <YoutubeEmbed embedId="zSKXlWN3my4" />
                    </article>
                    {/* <article data-aos='fade-up' className="container resultsContainer">
                        <p>Results</p>
                        <p>574 Youtube Video Playthroughs</p>
                    </article> */}
                </div>
            </section>
        </>
    )
}

export default BoababVideo