import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {clients} from '../../data';
import  './portfolio.css';
import AOS from 'aos';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';
import '../../components/filter.css'
import 'aos/dist/aos.css';


const Portfolio = () => {

  const [filter, setFilter] = useState("portfolio");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
      setProjects(clients);
  }, []);

  useEffect(() => {
      setProjects([]);

  const filtered = clients.map(p => ({...p, filtered : p.category.includes(filter)}));
  setProjects(filtered);
  }, [filter]);

  useEffect(()=>{
    AOS.init({
      duration: 2500, // values from 0 to 3000, with step 50ms
      //offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
    });
    }, [])




  return (  
    <m.div
      initial={{x: "-100%"}}
      animate={{x: "-0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >

      <ScrollToTop/>

      <span className='clickToViewClient'>*click to view</span>

        <div className='filter_nav'>
          <button className='projects_filter_button'active={filter === "portfolio"} onClick={() => setFilter("portfolio")}>
              <a>
                  All
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "photos"} onClick={() => setFilter("photos")}>
              <a>
                  Photography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "videos"} onClick={() => setFilter("videos")}>
              <a>
                  Videography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "content"} onClick={() => setFilter("content")}>
              <a>
                  Content Production
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "branding"} onClick={() => setFilter("branding")}>
              <a>
                  Branding
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "marketing"} onClick={() => setFilter("marketing")}>
              <a>
                  Marketing & Strategy
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "webdev"} onClick={() => setFilter("webdev")}>
              <a>
                  Web Dev
              </a>
          </button>
        </div>

        <div className="gallery_container">
          {projects.map(item => item.filtered === true ? 
              <article className='portfolio_container' key={item.id}>
              <Link to={item.path} data-aos='fade-up' >
                  <div className='portfolio_image_container'>
                      <img src={item.image} alt={item.image} />
                  </div>
                  <div className='portfolio_client_name'>
                    <h3>{item.name}</h3>
                    <br/>
                    <h4>Client: {item.name}</h4>
                  </div>
                </Link>
            </article>
          : " "
          )}
        </div>
    </m.div>
  )
}

export default Portfolio