import Header from '../../components/Header';
import HeaderImage from '../../images/header_bg_one.png';
import Card from '../../UI/Card';
import { Link } from 'react-router-dom';
import {plans} from '../../data';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';
import './plans.css';

const Plans = () => {
  return (
    <m.div
      initial={{x: "-100%"}}
      animate={{x: "-0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >
      <ScrollToTop/>
      <Header title='Payment Plans' image={HeaderImage}>
        
      </Header>

      <section className='container plans-page-nav-cont'>

        <div className='plans-btn-heading'>
          <p>How would you like to Market your Business?</p>
        </div>
            
        <div className="plans-page-navigation">
          <div className="plans-button-one">
            <Link to='/social'>
                <button>
                  Social Media
                </button>
            </Link>
          </div>
          <div className="plans-button-two">
            <Link to='/ppc'>
                <button>
                  Paid Media
                </button>
            </Link>
          </div>
          <div className="plans-button-three">
            <Link to='/seo'>
                <button>
                  SEO
                </button>
            </Link>
          </div>
        </div>
      </section>



      <section className="plans">

        <div className="container plans_container">
          {
            plans.map(({id, name, desc, path, features}) => {
              return <Card key={id}>
                <h3>{name}</h3>
                <small>{desc}</small>
                <h4>Features</h4>
                <div className='plans_text_content'>
                  {
                    features.map(({feature, available}, index) => {
                      return <p key={index} className={!available ? 'disabled' : ''}>
                        {feature}
                      </p>
                    })
                  }
                </div>
                <Link to={path} className='btn sm'>Choose Service</Link>
              </Card>
            })
          }
        </div>
      </section>
    </m.div>
  )
}

export default Plans