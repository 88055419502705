import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home.jsx';
import About from './pages/about/About.jsx';
import AdCart from './pages/adcart-home/AdCart.jsx';
import AdCartAbout from './pages/adcart-about/AdCartAbout.jsx';
import AdCartMagazine from './pages/adcart-magazine/AdCartMagazine.jsx';
import AdCartDownloads from './pages/adcart-downloads/AdCartDownloads.jsx';
import Services from './pages/services/Services.jsx';
import Photgraphy from './pages/photography/Photography.jsx';
import Videography from './pages/videography/Videography.jsx';
import Plans from './pages/plans/Plans.jsx';
import Ppc from './pages/ppc/Ppc.jsx';
import Seo from './pages/seo/Seo.jsx';
import Social from './pages/social/Social.jsx'; 
import Portfolio from './pages/portfolio/Portfolio.jsx';
import Contact from './pages/contact/Contact.jsx';
import AhbcPhoto from './pages/ahbc-photo/AhbcPhoto.jsx';
import AhbcVid from './pages/ahbc-video/AhbcVid.jsx';
import VintagePhoto from './pages/vintage-photo/VintagePhoto.jsx';
import VintageVideo from './pages/vintage-video/VintageVideo.jsx';
import TreVideo from './pages/tre-video/TreVideo.jsx';
import ErdemannVideo from './pages/erdemann-video/ErdemannVideo.jsx';
import BoababVideo from './pages/boabab-video/BoababVideo.jsx';
import AhacVideo from './pages/ahac-video/AhacVideo.jsx';
import AhacPhoto from './pages/ahac-photo/AhacPhoto.jsx';
import SupremeDental from './pages/sd/SupremeDental.jsx';
import NotFound from './pages/notFound/NotFound.jsx';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';

const App = () => {
  return (
    <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='services' element={<Services/>}/>
          <Route path='photography' element={<Photgraphy/>}/>
          <Route path='videography' element={<Videography/>}/>
          <Route path='plans' element={<Plans/>}/>
          <Route path='ppc' element={<Ppc/>}/>
          <Route path='seo' element={<Seo/>}/>
          <Route path='social' element={<Social/>}/>
          {/* <Route path='team' element={<Team/>}/> */}
          <Route path='projects' element={<Portfolio/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='ahbc-photo' element={<AhbcPhoto/>}/>
          <Route path='vintage-photo' element={<VintagePhoto/>}/>
          <Route path='vintage-video' element={<VintageVideo/>}/>
          <Route path='ahbc-video' element={<AhbcVid/>}/>
          <Route path='tre-video' element={<TreVideo/>}/>
          <Route path='erdemann-video' element={<ErdemannVideo/>}/>
          <Route path='boabab-video' element={<BoababVideo/>}/>
          <Route path='ahac-video' element={<AhacVideo/>}/>
          <Route path='ahac-photography' element={<AhacPhoto/>}/>
          <Route path='sd' element={<SupremeDental/>}/>
          <Route path='adcart-home' element={<AdCart/>}/>
          <Route path='about-adcart' element={<AdCartAbout/>}/>
          <Route path='adcart-magazine' element={<AdCartMagazine/>}/>
          <Route path='adcart-downloads' element={<AdCartDownloads/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      <Navbar/>
      <Footer/>
    </BrowserRouter>
  )
}

export default App