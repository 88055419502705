import ShoppingCart from '../../images/shopping-cart.png';
import RightArrow from '../../images/right-arrow.png';
import ScrollToTop from '../../components/ScrollToTop';
import Subscribe from '../../components/Subscribe';
import WhatsAppUs from '../../components/WhatsAppUs';
import './adcart.css';

const AdCart = () => {
    return (
        <>
        <ScrollToTop />
            <section className="about-adcart">  
                <div className="adcart-container container">
                    <div className="adcart-content">
                        <div className="adcart-about-text-cont">
                            <h3 className='about-adcart-heading'>About The Ad Cart</h3>
                            <br/>
                            <p>
                                The AdCart is a quarterly publication, designed and printed in Nairobi - Kenya by Rich Mansion Limited. It is a free advertiser magazine focusing on businesses seeking to reach their clients within defined regional demographics.
                                Our quarterly magazine is published and distributed on a rotational basis in the months of March, June, September & December. With this schedule, it will be easier for you to plan ahead on any special services or offers you’d wish to showcase with a planned budget.
                            </p>
                            <br/>
                            <div>
                                <h4>Our Vision</h4>
                                <p>
                                    To see growth in business synergy supported by a reliable advertising partner.
                                </p>
                            </div>

                        </div>
                        <div className="adcart-about-img-cont">
                            <img src={ShoppingCart} alt="Shopping Cart" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="adcart-content-two">
                        <h3>WHY THE AD CART ADVERTISER MAGAZINE</h3>
                        <div className='why-adcart-div-one'>
                            <article>
                                <h4>Affordability</h4>
                                <p>
                                    Our cost of advertising will be affordable to micro, small and medium enterprises. Whether you are an established enterprise or just a startup, you can be sure to get an advertising slot for as low as Ksh. 1499/-.
                                </p>
                            </article>
                            <article>
                                <h4>Coverage</h4>
                                <p>
                                    Unlike a brochure or flyer distributed in the streets, this magazine gets into the hands of more than 100,000 targeted readers on both hard and softcopy versions.
                                </p>
                                <p>
                                    Whilst a brochure or flyer is easily thrown away in the next bin on the streets. Our advertiser magazine sitting in another office reception is not only advertising your business elsewhere but also entertaining your walk-in clients in your own office.
                                </p>
                                <p>
                                    Our magazines will be distributed to our clients and offices which will subscribe to our physical & online copies. Other focus distribution areas include but not limited to: Hospital receptions, Hotel receptions, Private & Public commercial offices, Mobile Telegram Channels & Email subscriptions.
                                </p>
                            </article>
                            <article>
                                <h4>Professionalism</h4>
                                <p>
                                    From the pitch, marketing, and delivery of all our magazines, we intend to incorporate the highest levels of professionalism.
                                </p>
                            </article>
                            <article>
                                <h4>Unbiased</h4>
                                <p>
                                    -Our magazine will not focus on any political affiliations.
                                    -Our magazine will not focus on any religious affiliations.
                                    -Bookings will be on a first-come, first-served basis.
                                </p>
                            </article>
                            <article>
                                <h4>Cost</h4>
                                <p>
                                    We shall take full responsibility for the costs of marketing, publication and distribution of these magazines.
                                </p>
                            </article>
                            <article>
                                <h4>Convenience</h4>
                                <p>
                                    Do you wish to advertise but do not know where to begin with conceptualization? Worry not. We have a team of well-experienced graphic designers who will walk the journey of design with you. They will share the advert design with you for reviews and approvals before we hit the print button.
                                </p>
                            </article>
                            <article>
                                <h4>Accountability</h4>
                                <p>
                                    As proof of delivery, we purpose to keep a rotational distribution record of all our published magazines which will be made available to you as a client on request. This will ensure that we own up to our regional coverage and meet the desired targeted audiences.
                                </p>
                            </article>
                        </div>
                    </div>
                    <div className="adcart-content-three">
                        <h3>OUR ADVERTISING FUNNEL</h3>
                        <div className='content-three-div'>
                            <article>
                                <p>1. Identify a desired advertising space.</p>
                            </article>
                            <article>
                                <p>2. Make a booking</p>
                                <p>
                                    <span className='booking-steps'>
                                    -	Fill in the booking form
                                    </span>
                                    <br />
                                    <span className='booking-steps'>
                                    -	Pay 50% for adverts that require design services or 100% for ready adverts
                                    </span>
                                </p>
                            </article>
                            <article>
                                <p>3. Artwork design</p>
                            </article>
                            <article>
                                <p>4. Design consent .</p>
                            </article>
                            <article>
                                <p>5. Printing.</p>
                            </article>
                            <article>
                                <p>6. Distribution.</p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <WhatsAppUs />
        </>
    )
}

export default AdCart