import { useEffect } from 'react';
import Image from '../images/values_image_one.png';
import SectionHead from './SectionHead';
import {GiCutDiamond} from 'react-icons/gi';
import {values} from '../data';
import Card from '../UI/Card';
import '../pages/home/home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Values = () => {
    useEffect(()=>{
        AOS.init({
          duration: 2500, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
    }, [])
    return (
        <section className='values'>
            <div className='container values_container'>
                    <div className="values_left">
                        <div data-aos='fade-up' className="values_image">
                                <img src={Image} alt="Values Image" />
                        </div>
                    </div>
                <div className="values_right">
                    <SectionHead icon={<GiCutDiamond/>} title='Values'/>
                    <p>
                        We treasure honesty, transparency, and ethical behavior in all of our business practices, and we strive to be a trusted partner.
                    </p>
                    <div className="values_wrapper">
                        {
                            values.map(({id,icon,title,desc}) => {
                                return <Card key={id} className='values_value'>
                                    <span className='values_card_icon'>{icon}</span>
                                    <h4 className='values_card_title'>{title}</h4>
                                    <small className='values_card_desc'>{desc}</small>
                                </Card>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Values