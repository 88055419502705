
import {Link} from 'react-router-dom';
import { useEffect } from 'react';
import '../pages/home/home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainHeader = () => {
  useEffect(()=>{
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, [])
  return (
    <header className="main_header">
      <div className="container main_header_container">
        <div data-aos="fade-right" className="main_header_left">
          <h4>Hi, We are Rich Mansion.</h4>
          <h1> <s style={{fontWeight: 'lighter'}}>Your</s> <b>Marketing</b> & <s style={{fontWeight: 'lighter'}}>Design Footprint in</s> <b>Kenya.</b></h1>
          <p>Let's make great brands together.</p>
          <Link to='/plans' data-aos="zoom-in-down" className='btn lg'>Get Started</Link>
        </div>
        <div className="main_header_right">
          <div className="main_header_circle rotate"></div>
          <div className="main_header_image rotate_two">
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader