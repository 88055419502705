import { techstackicons } from '../data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './techstackcarousel.css'

const TechstackCarousel = () => {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                initialSlide: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 5,
                slidesToScroll: 4
                }
            }
        ]
};

    return (
        <>
            <Slider {...settings}>
                {
                    techstackicons.map((photo)=>{
                    return <div className="techstack_icons_container">
                        <div className="icon_card">
                            <img src={photo.src} alt="tech stack icons" />
                        </div>
                    </div>
                    })
                }
            </Slider>
        </>
    )
}

export default TechstackCarousel