import { useState, useEffect  } from 'react';
import SectionHead from './SectionHead';
import {ImQuotesLeft} from 'react-icons/im';
import Card from '../UI/Card';
// import avatar from '/images/avatar7.png';
import {AiFillLeftSquare} from 'react-icons/ai';
import {AiFillRightSquare} from 'react-icons/ai';
import {testimonials} from '../data';
import '../pages/home/home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';




const Testimonial = () => {

    const[index, setIndex] = useState(0);
    const {name, quote, job, avatar} = testimonials[index];

    const prevTestimonialHandler = () => {
        setIndex(prev => prev - 1);
        if(index <= 0){
            setIndex(testimonials.length - 1);
        }
    }


    const nextTestimonialHandler = () => {
        setIndex(prev => prev + 1);

        if(index >= testimonials.length - 1){
            setIndex(0);
        }
    }

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 30, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
    }, [])

    return (
        <section className='testimonials'>
            <div data-aos='fade-in' className="container testimonials_container">
                <SectionHead icon={<ImQuotesLeft/>} title='Testimonials' className='testimonials_head'/>
                <Card className="testimonial">
                    <div className="testimonial_avatar">
                        <img src={avatar} alt={name} />
                    </div>
                    <p className="testimonial_quote">{`"${quote}"`}</p>
                    <h5>{name}</h5>
                    <small className='testimonial_title'>{job}</small>
                </Card>
                <div className="testimonial_btn_container">
                    <button className="testimonial_btn" onClick={prevTestimonialHandler}><AiFillLeftSquare/></button>
                    <button className="testimonial_btn" onClick={nextTestimonialHandler}><AiFillRightSquare/></button>
                </div>
            </div>
        </section>
    )
}

export default Testimonial