import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import VintageCarousel from '../../components/vintageCarousel.jsx';
import HeaderClient from '../../images/vintage.jpg';
import { Parallax } from 'react-scroll-parallax';
import VINTAGE from '../../images/our clients/vr.png';
import './vintagephoto.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ClientOne = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <Header title='Vintage Residence' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-vr">
                                <img className="vr-client-logo" src={VINTAGE} alt="Vintage Residence Logo" />
                        </span>
                        <span className="client-name">Client: Vintage Residence</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Vintage Properties is a leading brand in Real Estate Industry in Kenya. The conglomerate has strategic business units. With operations ranging from the promotion of Real Estate, Building, Construction, Supplies of building materials and hardware.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>Vintage Residence Rongai Located along Fatima South Road off Magadi Road: they are elegantly and decently built 2 bedroom units with a master en-suite; 2 balconies with modern finishes in every apartment corner. We enjoyed shooting this project.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3><p>Rongai, Fatima South Road off Magadi Road.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3><p>Photography</p>
                        </article>
                    </div>
                    <article>
                        <VintageCarousel/>
                    </article>
                </div>
            </section>
        </>
    )
}

export default ClientOne