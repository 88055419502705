import { Link } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';
import {FaRegFilePdf} from 'react-icons/fa';
import Subscribe from '../../components/Subscribe';
import WhatsAppUs from '../../components/WhatsAppUs';
import './adcartdownloads.css';

const AdCartDownloads = () => {

  const filePath1 = require('../../pdf/The-AdCart-Advertising-Page-Size-Guide.pdf');
  const filePath2 = require('../../pdf/The-AdCart-Advertising-Rate-Card.pdf');
  const filePath3 = require('../../pdf/The-AdCart-General-Terms-and-Conditions.pdf');

  return (
    <>
      <ScrollToTop />
      <section className='adcart-downloads'>
        <div className='adcart-downloads-container container'>
          <div className='adcart-downloads-content'>
            <a href={filePath1}  download='The-AdCart-Advertising-Page-Size-Guide.pdf' target='_blank'>
              <div className="pdf-container pdf-container-one">
                <span className='pdf-name'>
                    <p>The AdCart Advertising Page Size Guide</p>
                </span>
                <span className='pdf-file'>
                  <FaRegFilePdf className="pdf-icon"/>
                </span>
              </div>
            </a>
            <a href={filePath2}  download='The-AdCart-Advertising-Rate-Card.pdf' target='_blank'>
              <div className="pdf-container pdf-container-two">
                <span className='pdf-name'>
                    <p>The AdCart Advertising Rate Card</p>
                </span>
                <span className='pdf-file'>
                  <FaRegFilePdf className="pdf-icon"/>
                </span>
              </div>
            </a>
            <a href={filePath3}  download='The-AdCart-General-Terms-and-Conditions.pdf' target='_blank'>
              <div className="pdf-container pdf-container-three">
                <span className='pdf-name'>
                    <p>The AdCart General Terms & Conditions</p>
                </span>
                <span className='pdf-file'>
                  <FaRegFilePdf className="pdf-icon"/>
                </span>
              </div>
            </a>
          </div>
          <div className="adcart-space-guide-mag-cont">
            <div>
              <h3>Flip through the magazine</h3>
            </div>
            <iframe className='adcart-iframe-downloads' style={{width:550,height:350}} src={"https://online.anyflip.com/bnbom/ipbh/index.html"}  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowFullscreen="true" ></iframe>
          </div>
        </div>
      </section>
      <Subscribe />
      <WhatsAppUs />
    </>
  )
}

export default AdCartDownloads