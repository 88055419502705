import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {photographyClients} from '../../data';
import  './photography.css';
import AOS from 'aos';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';
import '../../components/filter.css'
import 'aos/dist/aos.css';


const Portfolio = () => {

  const [filter, setFilter] = useState("pportfolio");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
      setProjects(photographyClients);
  }, []);

  useEffect(() => {
      setProjects([]);

  const filtered = photographyClients.map(p => ({...p, filtered : p.category.includes(filter)}));
  setProjects(filtered);
  }, [filter]);

  useEffect(()=>{
    AOS.init({
      duration: 2500, // values from 0 to 3000, with step 50ms
      //offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
    });
    }, [])


  return (  
    <m.div
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >

      <ScrollToTop/>

        <div className='photography-page-heading-container'>
          <h2>Photography</h2>
        </div>

        <div className='filter_nav-photo'>

        <br/>

          <button className='projects_filter_button'active={filter === "pportfolio"} onClick={() => setFilter("pportfolio")}>
              <a>
                  All
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "ephoto"} onClick={() => setFilter("ephoto")}>
              <a>
              Event photography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "pphoto"} onClick={() => setFilter("pphoto")}>
              <a>
              Product Photography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "rphoto"} onClick={() => setFilter("rphoto")}>
              <a>
              Real estate photography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "cphoto"} onClick={() => setFilter("cphoto")}>
              <a>
              Corporate photography
              </a>
          </button>
          <button className='projects_filter_button' active={filter === "cophoto"} onClick={() => setFilter("cophoto")}>
              <a>
              Commercial photography
              </a>
          </button>
        </div>

        <div className="gallery_container">
          {projects.map(item => item.filtered === true ? 
              <article className='portfolio_container' key={item.id}>
              <Link to={item.path} data-aos='fade-up' >
                  <div className='portfolio_image_container'>
                      <img src={item.image} alt={item.image} />
                  </div>
                  <div className='portfolio_client_name'>
                    <h3>{item.name}</h3>
                    <br/>
                    <h4>Client: {item.name}</h4>
                  </div>
                </Link>
            </article>
          : ""
          )};
        </div>
    </m.div>
  )
}

export default Portfolio