import { useState} from 'react';
import {Link} from 'react-router-dom';
import { serviceslinks } from '../data';
import './dropdown.css';


const Dropdown = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <ul onClick={handleClick}
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
            >
                {serviceslinks.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    );
};

export default Dropdown;