import { Link } from "react-router-dom";
import './subscribe.css';

const Subscribe = () => {
    return (
        <section className="forms">
            <div className="forms-container container">
                <div className="forms-content">
                    <div className="booking-form">
                        <h2 className="bookingform-text">The AdCart Booking Form</h2>
                        <p className="bookingform-text">Fill out the form to onboard your business/service for the next action.</p>
                        <a href="https://bit.ly/theadcartbookingform" className="btn btn-lg bookingform-text" target="_blank">Booking Form</a>
                        {/* <Link to="https://bit.ly/theadcartbookingform" className="btn btn-lg" target="_blank">Book Now</Link> */}
                    </div>
                    <div id="mc_embed_signup">
                        <form action="https://richmansion.us9.list-manage.com/subscribe/post?u=e0d416b58ac840f0780759384&amp;id=62e6840753&amp;f_id=002217e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self">
                            <div id="mc_embed_signup_scroll">
                                <h2>subscribe to our newsletter</h2>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>
                                    <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" required />
                                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-FNAME">First Name </label>
                                    <input type="text" defaultValue="" name="FNAME" className="" id="mce-FNAME" />
                                    <span id="mce-FNAME-HELPERTEXT" className="helper_text"></span>
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-LNAME">Last Name </label>
                                    <input type="text" defaultValue="" name="LNAME" className="" id="mce-LNAME" />
                                    <span id="mce-LNAME-HELPERTEXT" className="helper_text"></span>
                                </div>
                                <div className="mc-field-group size1of2">
                                    <label htmlFor="mce-PHONE">Phone Number </label>
                                    <input type="text" name="PHONE" className="phone" defaultValue="" id="mce-PHONE" />
                                    <span id="mce-PHONE-HELPERTEXT" className="helper_text"></span>
                                </div>
                                <div id="mce-responses" className="clear foot">
                                    <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                    <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                                </div>
                                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_e0d416b58ac840f0780759384_62e6840753" tabIndex="-1" defaultValue="" /></div>
                                <div className="optionalParent">
                                    <div className="clear foot">
                                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn btn-lg mc-btn" />
                                    <p className="brandingLogo"><a href="http://eepurl.com/iqmodU" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" /></a></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Subscribe