import {Link} from 'react-router-dom';
import Logo from '../images/RM Black Logo.png';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import {AiFillYoutube} from 'react-icons/ai';
import YearDate from './YearDate';
import '../pages/home/home.css';



const Footer = () => {
    return (
        <footer>
            <div className="container footer_container">
                <article>
                    <Link to='/' className='logo footer_logo'>
                        <img src={Logo} alt='Footer Logo' />
                    </Link>
                    <p>
                    We are a team of passionate and experienced marketing professionals who are dedicated to helping our clients achieve their business goals through innovative & effective marketing campaigns. With an emphasis on data-driven strategies and a customer-centric approach. 
                    </p>
                    <div className="footer_socials">
                        <a href="https://www.facebook.com/richmansionKE" target='_blank' rel="noreferrer">
                            <FaFacebookF/>
                        </a>
                        <a href="https://twitter.com/richmansionKE" target='_blank'rel="noreferrer">
                            <AiOutlineTwitter/>
                        </a>
                        <a href="https://www.instagram.com/richmansion.ke" target='_blank'rel="noreferrer">
                            <AiFillInstagram/>
                        </a>
                        <a href="/" target='_blank'rel="noreferrer">
                            <AiFillYoutube/>
                        </a>
                    </div>
                </article>
                <article>
                    <h4>Quickinks</h4>
                        <div className="footer_links">
                        <Link to='/about' className='permalink'>Who We Are</Link>
                        <Link to='/services' className='permalink'>Services</Link>
                        <Link to='/plans' className='permalink'>Plans</Link>
                        <Link to='/projects' className='permalink'>Projects</Link>
                        <Link to='/contact' className='permalink'>Get in touch</Link>
                    </div>
                </article>
                <article>
                    <h4>Contact Information</h4>
                    <ul className='footer_contact_list'>
                        <li>P.O. Box 5824 - 00200, Nairobi</li>
                        <li>Pioneer House, Kenyatta Avenue,</li>
                        <li>6th-Floor Office No. 614.</li>
                        <li>sales@richmansion.co.ke</li>
                        <li>+254 750 03 04 05.</li>
                    </ul>
                </article>
                <article>
                    <h4>Get In Touch</h4>
                    <Link to='/contact' className='permalink'>
                        <button className='footer_contact_page_button'>
                            Contact Us
                        </button>
                    </Link>
                    {/* <Link to='/s' className='permalink'>Support</Link> */}
                </article>
            </div>
            <div className="footer_copyright">
                <small> <YearDate/> RichMansion &copy; All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer