import WhatsAppUs from '../../components/WhatsAppUs';
import './adcartmagazine.css';


const AdCartMagazine = () => {

    return(
        <>
        <section className="adcart-magazines">
            <div className="container adcart-magazines-cont">
                <div className="magazines">   
                    <iframe style={{width:550,height:350}} src={"https://online.anyflip.com/bnbom/tnxo/index.html"}  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowFullScreen="true" ></iframe>
                    <iframe style={{width:550,height:350}} src={"https://online.anyflip.com/bnbom/vcyl/index.html"}  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowFullScreen="true" ></iframe>
                </div>
            </div>
        </section>
        <WhatsAppUs />
        </>
    )
}

export default AdCartMagazine