import { useEffect } from 'react';
import Header from '../../components/Header';
import HeaderImage from '../../images/header_bg_one.png';
import {MdEmail} from 'react-icons/md';
import {BsMessenger} from 'react-icons/bs';
import {IoLogoWhatsapp} from 'react-icons/io';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import AOS from 'aos';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';

import 'aos/dist/aos.css'

const Contact = () => {

const form = useRef();

const sendEmail = (e) => {

  e.preventDefault();

  emailjs.sendForm('service_1j1jowe', 'template_r8c6d59', form.current, 'i1yxR9pA_jKqsqx3A')
  .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });

  e.target.reset();

}

useEffect(()=>{
  AOS.init({
    duration: 2500, // values from 0 to 3000, with step 50ms
    //offset: 120, // offset (in px) from the original trigger point
    delay: 50, // values from 0 to 3000, with step 50ms
    easing: 'ease-in-out-cubic', // default easing for AOS animations
  })
},[])

  return (
    <m.div
      initial={{x: "-100%"}}
      animate={{x: "-0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >

      <ScrollToTop/>
      
      <Header title='Contact Us Today' image={HeaderImage}>

      </Header>
      <section className="contact">
        <div className="container contact_container">
            <div className="form_wrapper">
                <h4>Get In Touch</h4>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='name' placeholder='Your full name' required/>     
                    <input type="text" name='email' placeholder='Your Email' required/>
                    <textarea name="message" id="" cols="30" rows="10" placeholder='Your Message' required></textarea>
                    <button className="contact_form_btn">Send Message</button>              
                </form>
            </div>
            <div className="contact_wrapper">
                <div className="contact_wrapper_contents">
                  <a href="mailto:mansionarts@gmail.com" target='_blank' rel='noreferrer noopener'>
                    <MdEmail/>
                  </a>
                  <p>Email Us</p>
                </div>
                <div className="contact_wrapper_contents">
                  <a href="https://m.me/mansionarts" target='_blank' rel='noreferrer noopener'>
                    <BsMessenger/>
                  </a>
                  <p>Messenger</p>
                </div>
                <div className="contact_wrapper_contents">
                  <a href="https://wa.me/+254725000000" target='_blank' rel='noreferrer noopener'>
                    <IoLogoWhatsapp/>
                  </a>
                  <p>WhatsApp Us</p>
                </div>
              <div/>
            </div>
            <div className="contact_map_container">
                <h4>Location</h4>
                <iframe data-aos='fade-up' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.815122680576!2d36.81764681430534!3d-1.2848825359859486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d6cc5cfba9%3A0x59aad9db8b6b47a3!2sPioneer%20House%2C%20Kenyatta%20Avenue%2C%20Nairobi!5e0!3m2!1sen!2snl!4v1662600836534!5m2!1sen!2snl" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Location"></iframe>
            </div>
        </div>
      </section>
    </m.div>
  )
}

export default Contact