import { useEffect } from 'react';
import TechstackCarousel from './TechstackCarousel';
import {GrTechnology} from 'react-icons/gr';
import SectionHead from './SectionHead';
import Heading from './Heading';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../pages/home/home.css'


const Technology = () => {


    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 30, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
    }, [])


    return (
        <section className='technology'>
            <Heading title={'Our Technology Stack'}/>
            <div className='container technology_container'>
                {/* <SectionHead title='Tech Stack'/> */}
                {/* icon={<GrTechnology/>} */}
                <div data-aos='fade-up' className='carousel_container'>
                    <TechstackCarousel/>
                </div>
            </div>
        </section>
    )
}

export default Technology