import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {videographyClients} from '../../data';
import  './videography.css';
import AOS from 'aos';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';

import '../../components/filter.css'
import 'aos/dist/aos.css';


const Portfolio = () => {

  const [filter, setFilter] = useState("vportfolio");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
      setProjects(videographyClients);
  }, []);

  useEffect(() => {
      setProjects([]);

  const filtered = videographyClients.map(p => ({...p, filtered : p.category.includes(filter)}));
  setProjects(filtered);
  }, [filter]);


  useEffect(()=>{
    AOS.init({
      duration: 2500, // values from 0 to 3000, with step 50ms
      //offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
    });
    }, [])




  return (  
    <m.div
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >

        <ScrollToTop/>

        <div className='videography-page-heading-container'>
            <h2>Videography</h2>
          </div>

          <div className='filter_nav-video'>
            <button className='projects_filter_button'active={filter === "vportfolio"} onClick={() => setFilter("vportfolio")}>
                <a>
                    All
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "evideo"} onClick={() => setFilter("evideo")}>
                <a>
                  Event Videography
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "rvideo"} onClick={() => setFilter("rvideo")}>
                <a>
                  Real Estate Videography
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "avideo"} onClick={() => setFilter("avideo")}>
                <a>
                  Advertisement Videography
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "cvideo"} onClick={() => setFilter("cvideo")}>
                <a>
                  Corporate Videography
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "pvideo"} onClick={() => setFilter("pvideo")}>
                <a>
                    Product Videography
                </a>
            </button>
            <button className='projects_filter_button' active={filter === "tvideo"} onClick={() => setFilter("tvideo")}>
                <a>
                    Testimonial Videography
                </a>
            </button>
          </div>

          <div className="gallery_container">
            {projects.map(item => item.filtered === true ? 
                <article className='portfolio_container' key={item.id}>
                <Link to={item.path} data-aos='fade-up' >
                    <div className='portfolio_image_container'>
                        <img src={item.image} alt={item.image} />
                    </div>
                    <div className='portfolio_client_name'>
                      <h3>{item.name}</h3>
                      <br/>
                      <h4>Client: {item.name}</h4>
                    </div>
                  </Link>
              </article>
            : ""
            )};
          </div>
    </m.div>
  )
}

export default Portfolio