import './adcartabout.css';

const AdCartAbout = () => {
    return (
        <>
            <h1>AdCartAbout</h1>
        </>
    )
}

export default AdCartAbout