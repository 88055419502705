
const YearDate = () => {
    return (
        <>
            {new Date().getFullYear()}
        </>
    )
}

export default YearDate
