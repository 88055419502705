import { useEffect } from 'react';
import MarketingImage from '../../images/marketing_three.png'
import CreativeImage from '../../images/creative_process.png'
// import ServicesCarousel from '../../components/ServicesCarousel';
// import Subscribe from '../../components/Subscribe';
import './services.css';
import AOS from 'aos';
import ScrollToTop from '../../components/ScrollToTop';
import { motion as m } from 'framer-motion';
import 'aos/dist/aos.css';

const Services = () => {

  useEffect(()=>{
    AOS.init({
      duration: 2500, // values from 0 to 3000, with step 50ms
      //offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
    });
    }, [])

  return (
    <m.div
      initial={{x: "-100%"}}
      animate={{x: "-0%"}}
      transition={{ duration: 0.9, delay:0.7}}
      exit={{opacity: 1}}
    >
      
      <ScrollToTop/>

      <div className="services_container">

        {/* ====================================== */}

        <section className="sevice_main_container serviceTwo">
          <div className="container services_story_container">
            <div data-aos='fade-up' className="service_section_content">
              <h1>Marketing & Strategy</h1>
              <article className='marketing_services_article'>
                Being part of a very dynamic industry, it is prudent for your brand to keep up with changes in the digital landscape. Invest in powerful digital advertising & promotion methods that drive valuable sales for your business.
                To achieve effective advertising campaign, we reach out to people who are interested in your business and want to hear from you. We optimize and suite our marketing efforts to make sure we reach out to a specific relevant audience, via the the right platform, on the right device and at the right time.
                Out of all the perks, the most significant advantage of online advertising is the ability to target people specifically. Digital advertising is a level playing field for all businesses whether large or small. It is an affordable way to market your business to interested consumers.
                We offer various advertising & promotion methods that are budget-friendly while still achieving great performance outcomes of generating interest, leads and revenue for your business.
                We offer the following services:
              </article>
              <br />
              <ol>
                <li>
                  1. <b>Search Enginer Optimization SEO.</b>
                </li>
                <li>
                  2. <b>Google Search Advertising.</b> 
                </li>
                <li>
                  3. <b>Google Display Advertising.</b>
                </li>
                <li>
                  4. <b>Content Management and Analytics.</b> 
                </li>
                <li>
                  5. <b>Social Media Management, & Social Media Advertising.</b> 
                </li>
              </ol>
            </div>
          </div>
        </section> 

            {/* ====================================== */}

            <section className="sevice_main_container serviceThree service_section_image">
              <div className="container services_story_container">
                <div>
                  <img src={MarketingImage} alt="Story Image" />
                </div>
              </div>
            </section> 

            {/* ====================================== */}

          <section className="sevice_main_container serviceFour">
          <div className="container services_story_container">
            <div data-aos='fade-up' className="service_section_content">
              <h1>Creative</h1>
              <p className='marketing_services_article'>
                Can bold and creative content be an effective means of communication? Yes, we believe so and it is reflected in our designs for both physical and digital spaces.  
                We offer the following services:
              </p>
              <article className='marketing_services_article'>
                <h3>Video production & Photography</h3>
                <p>
                  Take advantage of one of the most popular forms of content currently which is video content production. We also include well done scripts to capture the attention of your relevant audience thus leading them to take the desired action.
                  Video is published across various places on the internet allowing your content to be found easily and consumed.
                </p>
              </article>
              <article className='marketing_services_article'>
                <h3>Digital Content Production</h3>
                <p>
                  Engage and capture your online audience with proper tailormade graphic content. Engage with them and strengthen the bond between the brand and customer through thoughtful, witty, & appropriate content.
                </p>
              </article>
            </div>
          </div>
        </section> 

            {/* ====================================== */}

            <section className="sevice_main_container serviceFive service_section_image">
              <div className="container services_story_container">
                <div>
                  <img src={CreativeImage} alt="Story Image" />
                </div>
              </div>
            </section> 

            {/* ====================================== */}

        <section className="sevice_main_container serviceSix">
          <div className="container services_story_container">
            {/* <div className="service_section_image">
              <img src={VisionImage} alt="Story Image" />
            </div> */}
            <div data-aos='fade-up' className="service_section_content">
              <h1>Development</h1>
              <p className='marketing_services_article'>
                Your website is the customer’s first impression of your brand.
                Every project starts with a strategy and a goal. And then it’s designed, step by step. Each step of our website development process has been refined to ensure your brand achieves the agreed on objectives. The process is as follows;
              </p>
              <article className='marketing_services_article'>
                <h3>Project scope</h3>
                <p>
                  Establishing the website’s scope of work (SoW) starts with an in-person meeting. A standard team comprises of a project manager, UI/UX designer, copywriter, digital analysts, and website developers. The goal for this is to map out the objectives, the process and set the timelines.
                </p>
              </article>
              <article className='marketing_services_article'>
                <h3>Wireframing</h3>
                <p>
                  User Experience is critical as it establishes a balance between what the business wants and the consumer needs. Without this balance, the website will have a bad experience, and it won’t be an asset for your brand. In this phase our UX designer begins the wireframing process. Developing the low and high resolution skeleton for your website which gives a look and feel of how each page will play out and the various interactions and integrations.
                </p>
              </article>
              <article className='marketing_services_article'>
                <h3>Design, Development & Production</h3>
                <p>
                  Once the wireframes are approved, the developer – who eat code and cereals for breakfast – jump in. This is basically the step where they make the website functional following best of industry standard website methodologies and technologies.
                  Back-end hosting environment and domain setup is also done at this stage.
                </p>
              </article>
              <article className='marketing_services_article'>
                <h3>Analytics</h3>
                <p>
                  Lastly, before go live, we integrate Google Analytics to your site to enable tracking of user behavior on the website to identify areas of improvement and optimize accordingly.
                </p>
              </article>
            </div>
          </div>
        </section> 

            {/* ====================================== */}

        <section className="sevice_main_container serviceSeven">
          <div className="container services_story_container">
            {/* <div className="service_section_image">
              <img src={VisionImage} alt="Story Image" />
            </div> */}
            <div data-aos='fade-up' className="service_section_content">
              <h1> Branding</h1>
              <p>
                Your brand is your company's personality.
                It's the image that you present to the world and how you want to be perceived. It's about what you stand for, what you believe in, what problems your business solves? & why people should care about your product or service. <br/> We will guide you in:
              </p>
              <article className='marketing_services_article'>
                <ol>
                  <li>
                    <b>1.</b> We'll help you identify your optimum market position, so that when people see your logo or hear your name they know exactly what they're getting into.
                  </li>
                  <li>
                  <b>2.</b> We'll develop an awareness and engagement strategy for your business that gets people engaged with your brand and makes them feel like they belong to something bigger than themselves.
                  </li>
                  <li>
                  <b>3.</b> We'll execute a plan for awareness and engagement by first tackling the physical branding aspect of your business, by creating your designs for your logo, business card and other related branded company documents and items through graphic design, secondly we will tackle the digital aspect branding of your company through creation of brand awareness ads, setting up social media accounts & writing copy for social media posts, building your website, and developing a design system for your company.
                  </li>
                </ol>
              </article>
            </div>
          </div>
        </section> 

      </div>

      {/* <Subscribe/> */}

    </m.div>
  )
}

export default Services