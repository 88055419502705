import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import HeaderClient from '../../images/erdemann.png';
import { Parallax } from 'react-scroll-parallax';
import ScrollToTop from '../../components/ScrollToTop';
import YoutubeEmbed from "../../components/YoutubeEmbed";
import ERDEMANN from '../../images/our clients/erd.png';
import './erdemannvideo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ErdemannVideo = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          //offset: 120, // offset (in px) from the original trigger point
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <ScrollToTop/>
            <Header title='Erdemann Property Ltd' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo client-logo-erd">
                                <img className="erd-client-logo" src={ERDEMANN} alt="Erdemann Logo" />
                        </span>
                        <span className="client-name">Client: Erdemann Property Ltd</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Founded in 2003 and based in Nairobi – Kenya, ERDEMANN PROPERTY LIMITED (“EPL”) is a limited Liability Company specializing in property development. EPL provides decent, low-cost shelter to Kenyans by employing the latest technology in the art of building construction. We emphasize on delivery of quality and affordable housing with an unwavering dedication to our clients. EPL has built more than 4000 housing units from the year 2003 to date, and has cultivated numerous relationships which have earned us the trust of key stakeholders in the real estate industry.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>Great Wall Gardens 4, is one of Kenya’s Premier Development that brings the best of country living to the suburbs, located in Athi River along Mombasa Road, with Captivating views of the surrounding environs and bears closeness to the panoramic views of the Lukenya Hills.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p>Greatwall Gardens 4 is strategically located along Mombasa road, at the Namanga interchange after Tuff Foam Mattress on Shanghai Road, next to Greatwall Gardens 3.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3>
                            <p>Videography</p>
                        </article>
                    </div>
                    <article>
                    <YoutubeEmbed embedId="hAdpF4STw9U" />
                    </article>
                    {/* <article data-aos='fade-up' className="container resultsContainer">
                        <p>Results</p>
                        <p>712 Youtube Video Playthroughs</p>
                    </article> */}
                </div>
            </section>
        </>
    )
}

export default ErdemannVideo