import { useEffect } from "react";
import Header from "../../components/Header";
import Home from '../home/Home.jsx';
import { Link } from "react-router-dom";
import ServicesCarousel from '../../components/ServicesCarousel';
import AhacCarousel from '../../components/ahacCarousel';
import HeaderClient from '../../images/gallery3.png';
import { Parallax } from 'react-scroll-parallax';
import AHAC from '../../images/our clients/ahac.png';
import './ahac-photo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from '../../components/ScrollToTop';

const AhacPhoto = () => {

    useEffect(()=>{
        AOS.init({
          duration: 2000, // values from 0 to 3000, with step 50ms
          delay: 50, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-out-cubic', // default easing for AOS animations
        });
        }, [])
        
    return (
        <>
            <ScrollToTop/>
            <Header title='Arbor House Arbitration Center' image={HeaderClient}>
            
            </Header>
            <section className="clientone">
                <div className="clientone_container">
                    <div className="container client-desc-cont">
                        <span className="client-logo">
                                <img src={AHAC} alt="Arbor House Arbitration Center Logo" />
                        </span>
                        <span className="client-name">Client: Arbor House Arbitration Centre</span>
                    </div>
                    <div className="container challangeBrief">
                        <article data-aos='fade-up'>
                            <h2>About the company</h2>
                            <p>Arbor House Arbitration Center (A.H.A.C.) was set up with a view to providing a discreet, quiet and pleasant environment in which lawyers, other professionals and their clients could meet to resolve disputes. AHAC enjoys a number of attributes which make it an ideal venue for your next meeting, workshop, training or for conducting legal proceedings.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h2>The project</h2>
                            <p>AHAC is a center providing an ideal venue to professionals, especially those in the Legal sector, for all their dispute resolution cases. The center enjoys a number of attributes that make it an ideal venue for your next meeting, workshop, training or for conduct of legal proceedings.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Location</h3>
                            <p>AHAC is located in close proximity to most major commercial centers including the Central Business District(CBD), Westlands, Karen, The Junction, Lavington, Upper hill and Kilimani area.</p>
                        </article>
                        <article data-aos='fade-up'>
                            <h3>Project scope</h3>
                            <p>Videography & Photography</p>
                        </article>
                    </div>
                    <article>
                        <AhacCarousel/>
                        <br/>
                        <br/>
                        <ServicesCarousel/>
                    </article>
                    {/* <article data-aos='fade-up' className="container resultsContainer">
                        <h2 className='clientResultsHeading'>The Results</h2>
                        <ul>
                            <li>1. Lorem ipsum dolor sit amet.</li>
                            <li>2. Lorem ipsum dolor sit amet.</li>
                            <li>3. Lorem ipsum dolor sit amet.</li>
                            <li>4. Lorem ipsum dolor sit amet.</li>
                            <li>5. Lorem ipsum dolor sit amet.</li>
                            <li>6. Lorem ipsum dolor sit amet.</li>
                        </ul>
                    </article> */}
                </div>
            </section>
        </>
    )
}

export default AhacPhoto