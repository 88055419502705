
import MainHeader from '../../components/MainHeader';
// import Clients from '../../components/Clients';
import Programs from '../../components/Programs';
import Values from '../../components/Values';
// import Subscribe from '../../components/Subscribe';
import Technology from '../../components/Technology';
import FAQS from '../../components/FAQS'
import Testimonial from '../../components/Testimonial';
// import { motion as m } from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import WhatsAppUs from '../../components/WhatsAppUs';
import './home.css';

const Home = () => {
  return (
    <div
      // initial={{x: "-100%"}}
      // animate={{x: "-0%"}}
      // transition={{ duration: 0.9, delay:0.7}}
      // exit={{opacity: 1}}
    >
      <ScrollToTop/>
      <MainHeader/>
      {/* <Clients/> */}
      <Programs/>
      {/* <Subscribe/> */}
      <Technology/>
      <Values/>
      <FAQS/>
      <Testimonial/>
      <WhatsAppUs/>
    </div>
  )
}

export default Home